/** @jsx jsx */
import { jsx } from "theme-ui"

const ColorBlock = ({ offset }) => (
  <div style={{ backgroundColor: "#990066" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-6 sm:p-8">
        <div className="md:flex p-2 justify-center ">
          <div className="flex flex-col w-full">
            <p className="text-center text-white" style={{ fontSize: "21px"}}>
              "Behind every fearless player is a fearless coach who refused to let them be anything but the best they can be"
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ColorBlock
