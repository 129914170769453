/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"

import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`


export default function  Reflaction() {
   const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
  <div>
    <div className="relative overflow-hidden justify-center">
      <div className="mx-auto p-6 md:pt-12 sm:p-8">
       <Header>
            <p
              style={{
                fontWeight: 600,
                fontSize: 24,
              }}
              className="mb-8 text-xs sm:text-sm md:text-md"
            >
              Frequently Asked Questions
            </p>
          


            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>What is Coaching?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Coaching is the process of empowering an individual by raising awareness of self to get clarity and pursue life's vision, face challenges, and make decisions. Coaching helps you focus on strengths and future possibilities to maximize performance.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Who can avail of Coaching?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>1. Kickstart - Coaching is for anyone ready to make a change in their life. Some work on professional success, some work on personal; as we all know, they are interrelated. Suppose you feel that you can achieve more than you are now. Then, yes, Coaching is for you!
                </p>
                <p>
                Coaching is directly proportional to well-being. </p>
                <p>2. Stuck - When you need to make crucial decisions, deal with change, conflicts,  behavior patterns, or experience critical life events, yes, Coaching is for you! Being Zone Coaching covers key aspects of awareness of your Focus, Communication, and Accountability.
                </p>
                <p>3. Good to Great - On top of it, suppose you are already doing good, then Coaching plays an important role in your next BIG steps. Every step of yours requires clarity, collaboration, and strategy.
                </p>
                <p>Coaching helps identify the goal, strength, gap, perspective, process, and action and brings awareness to self in every step of the process.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>What can I expect from a coach, and how will a coach work with me? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>1. Your conversation with the coach is entirely private and confidential. Your transparency becomes very important. The more open you are, the more valuable partnering will be.
                </p>
                <p>2. The coach works with you by listening to you in a non-judgemental space and creates a supportive way to help you discover, maximize, and transform.
                </p>
                <p>3. The coach will help you to take control of your thoughts, emotions, and actions. 
                </p>
                <p>4. A coach works with you to minimize the interference to enhance your performance, daily challenges, goals, frustrations, deadlines, decisions, or listen as a sounding board.
                </p>
                <p>5. Finally, Coaching will focus on self-improvement, beliefs, values, actionable points, motivation, support system, and tracking system.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Does Coaching work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>If you are ready and willing to commit to your change, the results are guaranteed. Coaching is the model scientifically proven for generations. 
                </p>
                <p>Take any successful person in any industry, and there will be a Coach behind their success.
                </p>
                <p>The result is not magical; the magic is in the process; regularly working with a coach and committing yourself brings the desired result.
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Is this worth the cost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>You are working on intangibles like self-confidence, communication, awareness, strategy, etc.,
                </p>
                <p>Can you put a price on them? No, right!
                </p>
                <p>Yes, Investing in yourself and working with it is worth it. Coaching empowers your present and future. 
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Coaching is NOT therapy/counseling. How?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Coaching focuses on your strengths and challenges today to move forward for your NEXT GOAL and future. Coaching acts as positive reinforcement with awareness and builds the process for your actions.
                </p>
                <p>On the other hand, therapy focuses on unresolved problems from the past and mental illness.
                </p>
                <p>In Coaching, clients begin to see changes in their behavior, attitude, confidence, and expression within a few months.

                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Can I cancel my scheduled session?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Yes, you can cancel your scheduled session at least 12hrs in advance. We recommend you to use the plan in the current month, as there is no carry forward. 
                </p>
              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8bh-content"
              id="panel8bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Is my session video recorded?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>No
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel9bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>What happens if Coach cancels on me?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Being Zone will reschedule the session at a mutually agreed time by both Coach and the Customer. 
                </p>
              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10bh-content"
              id="panel10bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>What about Privacy and Confidentiality?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>All your personal and professional information shared in the session and platform is confidential. Being Zone has a signed confidentiality agreement with all Coaches. Except for your Coach, no other person will have access to your information. For more information on privacy and confidentiality, please check here.
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11bh-content"
              id="panel11bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>I have a few more questions and suggestions?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Please welcome; we will answer all the questions you have. We are happy to receive any suggestions to improve our services. Contact us with your queries and suggestion at reachus@being.zone 
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12bh-content"
              id="panel12bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Can I upgrade/downgrade my subscription?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Yes, you can. Please write to us about the plan you wish to go for, and we will process the same. Kindly send your request to reachus@being.zone 
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13bh-content"
              id="panel13bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>How do I cancel my subscription?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Yes, you can; we believe you have turned your life around through Coaching and equipped yourself for your beautiful journey. Kindly send your request for cancellation to reachus@being.zone
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')} className="mb-4" style={{ width: "800px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel14bh-content"
              id="panel14bh-header"
            >
              <Typography sx={{ width: '90%', flexShrink: 0 }} style={{ fontWeight: "600" }}>Do you offer a trial session?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Yes, schedule a trial session to experience Coaching. Coaching is a partnered journey; usually, it takes a minimum of three sessions to understand, investigate, and explore. A customer and coach become comfortable with session after session to reap great results. 
                </p>

              </Typography>
            </AccordionDetails>
            </Accordion>



        </Header>
      </div>
    </div>
  </div>
)}

