import React from "react"
import Layout from "../components/layout"
import Iquote from "../components/pricing/quote"
import Productpricing from "../components/pricing/pricing-block"
import Faqblock from "../components/pricing/faq-block"

export default function pricing() {
  return (
    <Layout>
      <Iquote />
      <Productpricing />
      <Faqblock />
    </Layout>
  )
}
