/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import { Link } from "gatsby"
import * as React from 'react';

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const features = [
  /*{
    bheading: "Not Sure Yet",
    heading: "FREE TRIAL",
    note: "LET ME TRY AND SEE how it works for me",
    promo: "",
    price: "ONE Session",
    subheading: "1:1 Intro Session + Trial Coaching Session",
    target: "https://buy.stripe.com/bIYcOP8m02hg8Te145",
    ctaLabel: "Lets Begin",
    isFeatured: false,
    isBgcolor: "#f3f3f3",
    
  },*/
  {
    heading: "KICKSTART",
    note: "1 MONTH : 2 SESSIONS",
    promo: "Now 20% off : apply FIRST20",
    sprice: "₹9500",
    price: "₹7200",
    subheading: "₹9500 after first month",
    target: "https://buy.stripe.com/28oeWX1XCcVU8Te002",
    isFeatured: false,
    ctaLabel: "SUBSCRIBE",
  },
  {
    heading: "PLUS",
    note: "3 MONTHS : 6 SESSIONS",
    promo: "Now 20% Off : apply FIRST20OFF",
    sprice: "₹27900",
    price: "₹22320",
    subheading: "₹27900 after first purchase",
    target: "https://buy.stripe.com/4gw023au8g866L63ch",
    isFeatured: true,
    ctaLabel: "SUBSCRIBE",
  }
]

const renderFeature = () => {
  let featuresData = []
  features.forEach((feature, index) => {
    featuresData.push(

      <div
        key={index}
        className="pay-shadow flex flex-col text-center w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4"
        style={{position: "relative",  backgroundColor: "{feature.isBgcolor}" }} 
      >

      <div className="self-center mt-2" style={{    position: "absolute",
            top: "-48px"
          }}
        >
        </div>
      {feature.isFeatured ? 
        (<div className="self-center mt-2" style={{    position: "absolute",
    top: "-22px"
  }}
>
          <div
            className="bg-gray-400 flex justify-center"
            style={{
          
    height: "28px",
    borderRadius: "4px",
    padding: "2px 12px",
    fontWeight: "bold",
    background: "#00ab69",
    color: "white",
    textTransform: "uppercase",
    fontSize: 14,
            }}
          >
          Most Popular
          </div>
        </div>)
:""}


        <h1
          className="uppercase ml-8 mr-8"
          style={{ fontSize: 20}}
        >
          {feature.heading}
        </h1>

        <div className="self-center">
          <p
            style={{ fontSize: 16 }}
            className="text-center mt-2 uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md"
          >
            {feature.note}
          </p>
          <p
            style={{  fontSize: 15, fontWeight: 600, color: "#009966"}}
            className=" ml-1 mr-1 mt-4 font-bold text-xs sm:text-sm md:text-md"
          >
            {feature.promo}
          </p>
          <h2 style={{ fontSize: 18 }} className="text-center uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md">
            <strike>{feature.sprice}</strike>
          </h2>
          <h2
            style={{ fontSize: 24 }}
            className="text-center uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md"
          >
            {feature.price}
          </h2>
          <p
            style={{  fontSize: 15, fontWeight: 500 }}
            className=" ml-4 mr-4 mb-4 text-xs sm:text-sm md:text-md"
          >
            {feature.subheading}
          </p>
          <Link
          target="_blank"
            to={feature.target}
            className="mt-2 mb-4 cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded" style={{  fontSize: 18, fontWeight: 600 }}
          >
            {feature.ctaLabel}
          </Link>
        </div>

      </div>
    )
  })
  return featuresData
}





export default function  Reflaction() {
   const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
  <div>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-6 sm:p-8">
      <Header>
          <div>
            <h2
              style={{
                fontWeight: 800,
              fontSize: 28,
              }}
              className="uppercase tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              GET STARTED to discuss for your
            </h2>
      
            <h2
              style={{
                fontWeight: 800,
                fontSize: 32,
                color: "#cc3366",
              }}
              className="uppercase tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl"
            >
              Clarity &middot; Goal &middot; Challenge
            </h2>
            
          </div>
        </Header>
        
        <div className="mt-8 flex flex-wrap justify-evenly">
           <div className="pay-shadow flex flex-col text-center w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" 
           style={{position: "relative",  backgroundColor: "#ffcc00" }} >
              <div className="self-center mt-2" style={{    position: "absolute",
                  top: "-48px"
                }}
              >
              </div>

              <h1
                className="uppercase ml-8 mr-8"
                style={{ fontSize: 20}}
              >
                FREE TRIAL
              </h1>

              <div className="self-center">
                <p
                  style={{ fontSize: 16 }}
                  className="text-center mt-2 uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md"
                >
                  LET ME TRY AND SEE how it works for me
                </p>

                <h2
                  style={{ fontSize: 24 }}
                  className="text-center uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md"
                >
                  ONE Session
                </h2>
                <p
                  style={{  fontSize: 16, fontWeight: 500, color: "#000" }}
                  className=" ml-4 mr-4 mb-4 text-xs sm:text-sm md:text-md"
                >
                  1:1 Intro Session + Trial Coaching Session
                </p>
                <Link
                target="_blank"
                  to={"https://form.typeform.com/to/DQ3mnyjA"}
                  className="mt-2 mb-4 cursor-pointer shadow bg-zone-cta--black focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded" style={{  fontSize: 18, fontWeight: 600 }}
                >
                  TRY COACHING FREE
                </Link>
              </div>
            </div>

            {renderFeature()}
        </div>

        <div className="flex flex-wrap justify-evenly">
          <p
              style={{
                
                fontSize: 15,
              }}
              className="text-xs sm:text-sm md:text-md font-bold"
            >
              All packages include initial 1:1 Intro session + Goal focused coaching session
            </p>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <p
              style={{
                
                fontSize: 15,
              }}
              className="text-xs sm:text-sm md:text-md font-bold"
            >
              with certified coaches, assessment, setting up strategy, action plan and resources
            </p>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <p
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}
              className="mt-2 text-xs sm:text-sm md:text-md para uppercase"
            >
              Every session leads you closer to your goal
            </p>
        </div>
      </div>
    </div>
  </div>
)}

